export enum ChatSearchParams {
  DialogId = 'dialogId',
  ConversationId = 'conversationId',
}

export const EmptyConversationId = 'empty';

export const sampleQuestions = [
  "TT门店收银口完美门店标准是什么？",
  "广州便利店的必分销清单？",
  "酸条的卖点有哪些？",
  "南区CVS完美门店图像",
  "天龙八步是什么？",
  "绿箭OTC是不是大卖场必分销SKU？",
  "可可脂和代可可脂的区别",
  "2024年的新品清单",
  "玛氏常用缩写有哪些？",
  "酸条的箱规是多少",
  "士力架35克的保质期是多长时间"
]


